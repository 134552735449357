import { useEffect, useState } from "react";
import parse from "html-react-parser";
import Nav from "../components/Nav";
import igorabout from "../img/igorabout.png";
import Footer from "../components/Footer";
import csslogo from "../img/css.png";
import htmllogo from "../img/html.png";
import jslogo from "../img/js.png";
import ghlogo from "../img/github.png";
import reactlogo from "../img/react.png";
import drums from "../img/drums.jpg";
import umbracologo from "../img/umbraco.jpg";
import sasslogo from "../img/sass.png";
import tslogo from "../img/ts.png";
import rxjslogo from "../img/rxjs.png";
import nodelogo from "../img/node.png";

export default function AboutPage({ post }) {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await fetch(
        "https://react-api.igorlech.dk/wp-json/wp/v2/aboutpage?_embed"
      );
      const data = await response.json();
      setPosts(data);
    }
    getData();
  }, []);

  return (
    <section className="aboutpage">
      <Nav />
      <div className="about-heading-cont">
        <h1 className="about-heading">about me</h1>
      </div>
      <div className="about-content-cont">
        <div className="about-photo-cont">
          <img src={igorabout} className="igorabout" alt="Igor Lech" />
          <img src={drums} className="igordrums" alt="Igor Lech" />
        </div>
        <div className="about-wrapper">
          {posts
            .slice(0)
            .reverse()
            .map((post) => (
              <div className="about-content" key={post.id}>
                {parse(post.content.rendered)}
              </div>
            ))}
        </div>
      </div>
      <div className="about-technologies-all">
        <div className="about-technologies-cont">
          <img
            src={reactlogo}
            className="technology-logo floating"
            id="react"
            alt="React Logo"
          />
          <img
            src={jslogo}
            className="technology-logo floating 2"
            id="js"
            alt="JavaScript Logo"
          />
          <img
            src={htmllogo}
            className="technology-logo floating3"
            id="html"
            alt="HTML Logo"
          />
          <img
            src={csslogo}
            className="technology-logo floating4"
            id="css"
            alt="CSS Logo"
          />
          <img
            src={sasslogo}
            className="technology-logo floating 4"
            id="sass"
            alt="Sass Logo"
          />
        </div>
        <div className="about-technologies-cont">
          <img
            src={ghlogo}
            className="technology-logo floating2"
            id="gh"
            alt="GitHub Logo"
          />
          <img
            src={tslogo}
            className="technology-logo floating"
            id="ts"
            alt="TypeScript Logo"
          />
          <img
            src={umbracologo}
            className="technology-logo floating3"
            id="umbraco"
            alt="Umbraco Logo"
          />
          <img
            src={rxjslogo}
            className="technology-logo floating5"
            id="rxjs"
            alt="RxJS Logo"
          />
          <img
            src={nodelogo}
            className="technology-logo floating4"
            id="node"
            alt="Node Logo"
          />
        </div>
      </div>
      <Footer />
    </section>
  );
}
