import codeicon from "../img/code-icon.png";
import designicon from "../img/design-icon.png";
import skillsicon from "../img/skills-icon.png";
import { Fade } from "react-awesome-reveal";

export default function SkillsSection() {
  return (
    <div className="skills-section">
      <Fade triggerOnce="true" direction="up">
        <h2 className="skills-heading" id="skills-heading">
          my main skills
        </h2>
      </Fade>
      <div className="skills-wrapper">
        <div className="hard-skills-coding skillblock">
          <div className="skills-img-cont">
            <img src={codeicon} alt="Code Icon" className="skills-img" />
            <div className="single-skill">
              <h3 className="skillname">Experienced in:</h3>
              <ul className="creativeskills-list coding-skills">
                <li className="skillname cs-listitem">HTML</li>
                <li className="skillname cs-listitem">CSS, Sass</li>
                <li className="skillname cs-listitem">
                  JavaScript, TypeScript
                </li>
                <li className="skillname cs-listitem">
                  C# and Razor (Umbraco)
                </li>
              </ul>
            </div>
            <div className="single-skill">
              <h3 className="skillname">Frameworks and libraries:</h3>
              <ul className="creativeskills-list coding-skills">
                <li className="skillname cs-listitem">React.js, Electron.js</li>
                <li className="skillname cs-listitem">RxJS</li>
              </ul>
            </div>
            <div className="single-skill">
              <h3 className="skillname">CMS:</h3>
              <ul className="creativeskills-list coding-skills">
                <li className="skillname cs-listitem">Umbraco, WordPress</li>
              </ul>
            </div>
            <div className="single-skill">
              <h3 className="skillname">Little experience with:</h3>
              <ul className="creativeskills-list coding-skills">
                <li className="skillname cs-listitem">
                  Next.js, Java, Python, SQL
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="hard-skills-other skillblock">
          <div className="skills-img-cont">
            <img src={designicon} alt="Code Icon" className="skills-img" />
            <div className="singleskill">
              <ul className="creativeskills-list">
                <li className="skillname cs-listitem">SEO and SoMe</li>
                <li className="skillname cs-listitem">
                  Adobe Creative Suit: XD, Photoshop, Premiere Pro, After
                  Effects, Lightroom
                </li>
                <li className="skillname cs-listitem">
                  Prototyping in XD and Figma, design systems
                </li>
                <li className="skillname cs-listitem">Graphic Design</li>
                <li className="skillname cs-listitem">Microsoft Office</li>
                <li className="skillname cs-listitem">Project management</li>
                <li className="skillname cs-listitem">Teamwork platforms</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="soft-skills skillblock">
          <div className="skills-img-cont">
            <img src={skillsicon} alt="Code Icon" className="skills-img" />
            <div className="singleskill">
              <ul className="creativeskills-list second">
                <li className="skillname cs-listitem">Teamwork skills</li>
                <li className="skillname cs-listitem">Open-minded</li>
                <li className="skillname cs-listitem">Proactive and eager</li>
                <li className="skillname cs-listitem">
                  Always willing to learn new technologies
                </li>
                <li className="skillname cs-listitem">Creative</li>
                <li className="skillname cs-listitem">Committed</li>
                <li className="skillname cs-listitem">Social and active</li>
                <li className="skillname cs-listitem">
                  Fluency in English, native Polish and intermediate level in
                  Danish and German
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
